
::v-deep p {
  margin: 0;
}

.mark-scrollbar {
  height: 1%;
  flex: 1;

  ::v-deep > .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .mark-content {
    display: flex;
    flex-direction: column;

    .mark-info {
      display: flex;

      .mark-info-left {
        .left-img {
          width: 100px;
          height: 140px;
          box-sizing: border-box;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .mark-info-right {
        display: flex;
        flex: 1;
        width: 1%;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px 0;
        box-sizing: border-box;

        .info-right-top, .info-right-bottom {
          padding: 0 64px;
          background: #F3FFF8;
          height: 50px;
          display: flex;
          align-items: center;

          span {
            font-size: 18px;
            display: inline-block;
            width: 30%;
          }

          &.info-right-bottom {
            span {
              &:first-of-type {
                width: 60%;
              }
            }
          }
        }
      }
    }

    .askAnswer-content {
      margin-top: 25px;
      display: flex;
      flex-direction: column;

      .askAnswer-title {
        display: flex;
        align-items: center;

        .green-block {
          width: 5px;
          height: 20px;
          background: #2DC079;
          border-radius: 3px;
          margin-right: 10px;
        }

        span {
          line-height: 1;

          &:first-of-type {
            font-size: 18px;
          }

          &:last-of-type {
            font-size: 16px;
            color: #999;
          }
        }
      }

      .askAnswer-item-box {
        margin-top: 26px;
        display: flex;
        flex-direction: column;

        .askAnswer-item {
          margin-bottom: 46px;

          .question-title {
            padding: 15px 24px;
            background: #F7F7F7;
            font-size: 16px;

            .title {
              ::v-deep p {
                font-size: 16px;
                display: inline;
              }

              ::v-deep img {
                max-width: 300px !important;
                max-height: 300px !important;
              }
            }
          }

          .student-answer {
            display: flex;
            flex-direction: column;
            margin: 0 42px;

            span {
              font-size: 16px;
              line-height: 1;
              margin: 12px 0;
            }

            .answer-input {

            }
          }

          .reference-answer {
            display: flex;
            flex-direction: column;
            margin: 14px 42px;

            .reference-answer-span {
              font-size: 16px;
              color: #FF0000;
              line-height: 1;
              margin-bottom: 4px;
            }

            .reference-answer-content {
              font-size: 14px;
              color: #666666;

              ::v-deep p {
                font-size: 14px;
                display: inline;
                color: #666666;
              }

              ::v-deep img {
                max-width: 300px !important;
                max-height: 300px !important;
              }
            }
          }

          .grade-score {
            margin: 0 42px;
            color: #666666;
            font-size: 16px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .bottom-btn {
      text-align: center;
    }
  }
}
